/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.menu ul{
  list-style-type:none;
} */

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background-color: #AAAAAA;
  border: 0 solid #e1e5ea;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #162337 !important;
  box-shadow: none !important;
}

.ant-picker-suffix {
  color: #162337 !important;
}

.ant-picker {
  border-radius: 4px !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.css-h4y409-MuiList-root {
  padding-top: 0;
}

.ant-picker-input>input {
  margin-top: 4px;
}